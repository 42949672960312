.valueContainer {
  padding: 16px 8px 8px 8px;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.notEditableContainer {
  overflow: auto;
  max-height: 500px;
}

.icon {
  color: #1890ff;
}

.iconContainer {
  padding-top: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.textArea {
  width: 100%;
  border: none;
  padding: 8px;
  max-height: 500px;
  resize: none;
}

.editCell {
  position: absolute;
  top: -20px;
  left: -8px; // 8px is the padding of the valueContainer
  z-index: 1;
  width: calc(100% + 16px); // 16px is the padding of the valueContainer
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: pre-wrap;
  word-wrap: break-word;
  padding-bottom: 8px;
}

.editCellContainer {
  position: relative;
}
